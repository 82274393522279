.dictionary-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.dictionary-table {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: #ddd;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #f4f4f4;
  font-weight: bold;
}

.table-header > div {
  padding: 12px;
}

.table-body {
  display: flex;
  flex-direction: column;
}

.table-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: white;
}

.table-row:nth-child(even) {
  background-color: #f9f9f9;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.table-row > div {
  padding: 12px;
}

.word-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.speaker-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.speaker-icon:hover {
  opacity: 1;
}

.search-container {
  margin-bottom: 20px;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
}

.search-input:focus {
  border-color: #007bff;
}

.search-input::placeholder {
  color: #999;
} 