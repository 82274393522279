* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  height: 4rem;
}

#container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 6rem);
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
}

#card-wrapper{
    display: flex;
    perspective: 1000px;
    width: 300px;
    height: 200px;
   
}

#card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    cursor: pointer;
}

#card.flipped {
    transform: rotateY(-180deg);
}

#font-card,
#back-card {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: white;
}

#font-card {
    background-color:lightblue;
}

#back-card {
    transform: rotateY(180deg);
    background-color: aquamarine;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    background: linear-gradient(45deg, #4CAF50, #45a049);
    color: white;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0,0,0,0.2);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(0,0,0,0.25);
        background: linear-gradient(45deg, #45a049, #4CAF50);
    }

    &:active {
        transform: translateY(1px);
        box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    }

    &#next {
      font-size: 20px;
      padding: 15px 30px;
    }
}

#speaker {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all .2s;

    &.hide {
        display: none;
    }
}

#buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}